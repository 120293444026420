<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <span class="brand-logo">
        <b-img :src="CollectiumLogo" alt="logo" />
      </span>
      <h2 class="brand-text pl-0">
        <b-img :src="CollectiumText" alt="logo" />
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p class="mb-2">Oops! 😖 The requested URL was not found on this server.</p>
        <b-button variant="success" class="mb-2 btn-sm-block" :to="{ path: '/' }">Back to home</b-button>
      </div>

      <b-img fluid :src="imgUrl" alt="Error page" style="height: 400px;"/>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import CollectiumText from '../../../assets/images/logo/teppanku.png'
import CollectiumLogo from '../../../assets/images/logo/teppanku.png'

export default {
  components: {
    CollectiumText,
    CollectiumLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
